import { isProduction } from '../../config';

const EVENTS = {
  login: {
    name: 'login',
    category: 'engagement',
    labelContent: 'method',
    interactive: true,
  },
  signUp: {
    name: 'sign_up',
    category: 'engagement',
    labelContent: 'method',
    interactive: true,
  },
  search: {
    name: 'search',
    category: 'engagement',
    labelContent: 'search_term',
    interactive: true,
  },
  select: {
    name: 'select_content',
    category: 'engagement',
    labelContent: 'content_type',
    interactive: true,
  },
  viewItem: {
    name: 'view_item',
    category: 'engagement',
    labelContent: 'view',
    interactive: false,
  },
  heroRegisterButton: {
    name: 'hero_register_button',
    category: 'engagement',
    labelContent: 'hero_register_button',
    interactive: true,
  },
  navRegisterButton: {
    name: 'nav_register_button',
    category: 'engagement',
    labelContent: 'nav_register_button',
    interactive: true,
  },
  footerRegisterButton: {
    name: 'footer_register_button',
    category: 'engagement',
    labelContent: 'footer_register_button',
    interactive: true,
  },
  navGoToPremiumButton: {
    name: 'nav_go_to_premium_button',
    category: 'engagement',
    labelContent: 'nav_go_to_premium_button',
    interactive: true,
  },
};

type EventKeys = keyof typeof EVENTS;

export type Events =
  | 'login'
  | 'signUp'
  | 'search'
  | 'select'
  | 'viewItem'
  | 'heroRegisterButton'
  | 'navRegisterButton'
  | 'footerRegisterButton'
  | 'navGoToPremiumButton';

export function addEvent(type: EventKeys, label?: string) {
  if (!isProduction) {
    return;
  }
  const event = EVENTS[type];
  // @ts-expect-error - gtag is globally defined in _app.tsx
  window.gtag('event', event.name, {
    'event_category': event.category || 'engagement',
    'event_label': label || '(not set)',
    'non_interaction': event.interactive ? false : true,
  });
}
