import clsx from 'clsx';
import Link from 'next/link';
import { ReactNode, ComponentProps } from 'react';

interface Props {
  className?: string;
  link?: string;
  id: string;
  onClick?: () => void;
  variant?: Variant;
  disabled?: boolean;
  mobileFullWidth?: boolean;
  children: ReactNode | string;
  linkProps?: Partial<ComponentProps<typeof Link>>;
}
export enum Variant {
  primary = 'yellow-btn',
  secondary = 'dark-purple-btn',
  outLine = 'white-btn',
}

export default function BaseButton({
  children,
  className,
  link = '',
  variant = Variant.primary,
  mobileFullWidth,
  disabled,
  onClick,
  id,
  linkProps,
}: Props) {
  if (!!link) {
    return (
      <Link
        href={link || ''}
        id={id}
        tali-btn="true"
        className={clsx(
          'not-italic text-tali-btn tracking-[-0.02em] font-inter inline-block',
          'px-14 py-5 text-center',
          { ['w-[19.438rem] desktop:w-auto']: mobileFullWidth },
          { [`${variant}`]: !disabled },
          `${className}`,
          { ['tali-btn']: !disabled },
          { ['tali-disable-btn']: disabled },
        )}
        {...linkProps}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <button
        id={id}
        onClick={onClick}
        disabled={disabled}
        className={clsx(
          'not-italic text-tali-btn tracking-[-0.02em] font-inter inline-block',
          'px-14 py-5 text-center',
          { ['w-[19.438rem] desktop:w-auto']: mobileFullWidth },
          { [`${variant}`]: !disabled },
          `${className}`,
          { ['tali-btn']: !disabled },
          { ['tali-disable-btn']: disabled },
        )}
      >
        {children}
      </button>
    );
  }
}
