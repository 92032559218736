'use client';
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import Link from 'next/link';
import { useContext } from 'react';
import {
  Image,
  ResponsiveImageType,
  StructuredText,
  StructuredTextDocument,
  TitleMetaLinkTag,
} from 'react-datocms';
import { Variant } from '~/components/Base/TaliButton';
import LoginButton from '~/components/buttons/LoginButon';
import Divider from '~/components/display/Divider';
import VideoParagraph, {
  muxVideoType,
} from '~/components/display/ImageParagraph';
import Text from '~/components/display/Text';
import { FeaturedCardContent } from '~/components/featuredBlogCard';
import SwiperComponent from '~/components/swiper';
import TestimonialCarousel from '~/components/testimonials/testimonialCarousel';
import TestimonialItem from '~/components/testimonials/testimonialItem';
import { AnalyticsContext } from '~/services/analytics/core';
import {
  AnalyticsClickEvent,
  AnalyticsClickEventElementId,
  AnalyticsClickEventLocation,
  AnalyticsClickEventName,
} from '~/services/analytics/type';
import { FeaturedCardType } from '~/types/feature';
import styles from './page.module.scss';
import { OurClients } from './OurClients';

type ResourcesPage = {
  readMoreText: string;
  readingDurationText: string;
  clock: { url: string };
  categoryFile: { url: string };
};
type QA = {
  answer: string;
  question: string;
  id: string;
  richAnswer?: StructuredTextDocument;
};
interface ImageParagraph {
  id: string;
  title: string;
  blurb: string;
  image: { alt: string; responsiveImage: ResponsiveImageType };
}

interface ContentButton {
  text: string;
  link: string;
  active: boolean;
}

type VideoParagraphType = {
  id: string;
  title: string;
  blurb: string;
  buttonLink?: Array<ContentButton>;
  image: {
    video: muxVideoType | null;
    responsiveImage: ResponsiveImageType | null;
  };
};

export type HomePageProps = {
  title: string;
  subtitle: string;
  startButtonText: string;
  shareLoadSaveTime: ImageParagraph[];
  featuresPartTitle: string;
  featuresFooterText: string;
  featuresButtonText: string;
  featuresPartVideoParagraph: VideoParagraphType[];
  seo: TitleMetaLinkTag[];
  partnershipTitle: string;
  partnershipBlurb: string;
  partnershipMember: {
    image: { responsiveImage: ResponsiveImageType };
  }[];
  clientTitle: string;
  clientBlurb: string;
  clientMember: {
    image: { responsiveImage: ResponsiveImageType };
  }[];
  seamlessIntegrationTitle: string;
  seamlessIntegrationBlurb: string;
  seamlessIntegrationBlurbText: StructuredTextDocument;
  seamlessIntegrationVideo: {
    video: muxVideoType;
  };
  featuredBlogsTitle: string;
  faqTitle: string;
  faqQa: QA[];
  testimonialSectionSubtitle: string;
  testimonialSectionTitle: string;
  testimonials: [
    {
      testimonialDescription: string;
      userId: string;
      userRole: string;
      avatar: {
        url: string;
      };
    },
  ];
};

export function HomePageClient(props: {
  content: HomePageProps;
  lastTwoFeaturedBlog: Array<FeaturedCardType>;
  resourcePage: ResourcesPage;
}): React.ReactElement {
  const { content } = props;
  const isMobile = useMediaQuery('(max-width:1000px)');
  const { trackClick } = useContext(AnalyticsContext);

  const GetStartedBtn = () => (
    <LoginButton
      text={`${content.startButtonText}`}
      className={styles.titleBtn}
      event="heroRegisterButton"
      onClick={() =>
        trackClick(AnalyticsClickEvent.REGISTER_BUTTON_CLICKED, {
          name: AnalyticsClickEventName.LOGIN,
          location: AnalyticsClickEventLocation.HOMEPAGE,
          elementId: AnalyticsClickEventElementId.HOMEPAGE_BUTTON,
          elementText: content.startButtonText,
        })
      }
    />
  );

  const ImageParagraph = (props: ImageParagraph) => {
    return (
      <div className={styles.ImageParagraphWrapper}>
        <div className={styles.imgPrgTextWrapper}>
          <Text
            type="light"
            size="h2"
            title={props.title}
            textAlign="start"
            containerStyle={
              isMobile ? styles.mobileParagraph : styles.paragraph
            }
            paddingBottom={!isMobile ? '24px' : '0'}
          />
          <Text
            type="light"
            size="p1"
            title={props.blurb}
            textAlign="start"
            containerStyle={
              isMobile ? styles.mobileParagraph : styles.paragraph
            }
          />
        </div>
        <div className={styles.image}>
          <Image data={props.image.responsiveImage} />
        </div>
      </div>
    );
  };
  const Qa = (props: QA) => {
    return (
      <div className={styles.faqCard}>
        <Text
          type="light"
          size="semiTitle"
          title={props.question}
          textAlign="start"
          containerStyle={styles.fullWidth}
        />
        <Text
          type="light"
          size="p2"
          title={
            props.richAnswer
              ? ((
                  <StructuredText data={props.richAnswer} />
                ) as unknown as string)
              : props.answer
          }
          textAlign="start"
          containerStyle={styles.fullWidth}
        />
      </div>
    );
  };
  const faqs = content.faqQa.map((category) => {
    return (
      <div key={category.id}>
        <Qa {...category} />
      </div>
    );
  });
  const posts = props.lastTwoFeaturedBlog.map((post) => {
    return (
      <div className={styles.featuredCard} key={post.id}>
        <FeaturedCardContent
          homePage
          readingDurationText={props.resourcePage.readingDurationText}
          isMobile={isMobile}
          readMoreText={props.resourcePage.readMoreText}
          clockUrl={props.resourcePage.clock.url}
          categoryFileURL={props.resourcePage.categoryFile.url}
          featuredImage={post.featuredImage}
          id={post.id}
          categoryName={post.category.categoryName}
          minutesReading={post.minutesReading}
          slug={post.slug}
          author={post.author}
          subtitle={post.subtitle}
          title={post.title}
          key={post.id}
          publishedDate={post.publishedDate}
        />
      </div>
    );
  });

  const ShareLoadSaveTime = content.shareLoadSaveTime.map((category) => {
    return (
      <div key={category.id}>
        <ImageParagraph {...category} />
      </div>
    );
  });

  const FeaturesImgPrg = content.featuresPartVideoParagraph.map((category) => {
    return (
      <div key={category.id}>
        <VideoParagraph
          withBackground
          title={category.title}
          body={category.blurb}
          image={category.image}
          {...(category.buttonLink?.[0]?.link && {
            buttonLink: {
              ...category.buttonLink[0],
              variant: Variant.secondary,
              className: styles.mobileButton,
            },
          })}
          reverseOrder={isMobile}
        />
      </div>
    );
  });

  const logos = content.partnershipMember.map((item) => {
    return (
      <div
        key={item.image.responsiveImage.alt}
        className={clsx(styles.logoWrapper, 'relative')}
      >
        <Image
          data={item.image.responsiveImage}
          layout="responsive"
          objectFit="contain"
          objectPosition="50% 50%"
        />
      </div>
    );
  });

  return (
    <>
      <div className={styles.wrapper}>
        <div id="title-container" className={styles.titleContainer}>
          <div className={styles.titleBlock}>
            <h1 className={styles.title}>{content.title}</h1>

            <div className={styles.titleBodyContainer}>
              <Text
                textAlign="center"
                size="p1"
                type="dark"
                title={content.subtitle}
              />
            </div>
            <GetStartedBtn />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.container}>{ShareLoadSaveTime}</div>
          <Divider />
          <div className={styles.container}>
            <div className={styles.titleWrapper}>
              <Text
                size="sudoH1"
                type="light"
                title={content.featuresPartTitle}
                textAlign="center"
              />
            </div>
            <div className={styles.feauturesVidepPrgContainer}>
              {FeaturesImgPrg}
            </div>
            <div className={styles.learnMoreContainer}>
              <Text
                size="p1"
                type="light"
                paddingTop="129px"
                textAlign="center"
                title={content.featuresFooterText}
              />
              <div className={styles.featureLearnMore}>
                <Link href="/features">
                  <button className={styles.learnMoreBtn}>
                    {content.featuresButtonText}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <Divider />
          <div className={styles.container}>
            <Text
              size="sudoH1"
              type="light"
              title={content.partnershipTitle}
              textAlign="center"
            />
            <Text
              size="p1"
              type="light"
              title={content.partnershipBlurb}
              paddingTop="50px"
              paddingBottom="76px"
              paddingLeft="30px"
              paddingRight="30px"
              textAlign="center"
            />
            <div className={styles.logosWrapper}>{logos}</div>
          </div>
          <Divider />
          <div className={styles.container}>
            <Text
              size="sudoH1"
              type="light"
              title={content.clientTitle}
              textAlign="center"
            />
            <Text
              size="p1"
              type="light"
              title={content.clientBlurb}
              paddingTop="50px"
              paddingBottom="76px"
              paddingLeft="30px"
              paddingRight="30px"
              textAlign="center"
            />
            <OurClients />
          </div>

          <Divider />

          <div className={styles.container}>
            <div className={clsx(styles.featureContainer, styles.compatible)}>
              <VideoParagraph
                containerStyle={styles.titleRtl}
                containerTextStyle={styles.compatibleTextContainer}
                title={content.seamlessIntegrationTitle}
                body={
                  (
                    <StructuredText
                      data={content.seamlessIntegrationBlurbText}
                    />
                  ) as unknown as string
                }
                image={{ video: content.seamlessIntegrationVideo.video }}
              />
            </div>
          </div>
          <Divider />
          <div className={styles.container}>
            <Text
              size="h2"
              type="light"
              title={content.testimonialSectionTitle}
              textAlign="center"
              containerStyle={styles.testimonialTitle}
            />
            <Text
              size="p1"
              type="light"
              title={content.testimonialSectionSubtitle}
              textAlign="center"
              textStyle={styles.testimonialBlurb}
              containerStyle={styles.testimonialBlurbContainer}
            />
            {!isMobile ? (
              <TestimonialCarousel
                className={styles.carouselContainer}
                testimonials={content.testimonials}
              />
            ) : (
              <div className={styles.swiperComponentContainer}>
                <SwiperComponent
                  renderItem={(post) => {
                    return <TestimonialItem item={post} isMobile={true} />;
                  }}
                  items={content.testimonials}
                  numShow={1}
                />
              </div>
            )}
          </div>
          <Divider />
          <div className={styles.container}>
            <Text
              size="h2"
              type="light"
              title={content.featuredBlogsTitle}
              textAlign="center"
              containerStyle={styles.titleWrapper}
            />
            {!isMobile ? (
              <div className={styles.featuredCardsWrapper}>{posts}</div>
            ) : (
              <div className={styles.swiperComponentContainer}>
                <SwiperComponent
                  renderItem={(post) => {
                    return (
                      <div
                        className={styles.featuredCardMobile}
                        draggable={false}
                      >
                        <FeaturedCardContent
                          homePage
                          readingDurationText={
                            props.resourcePage.readingDurationText
                          }
                          isMobile={isMobile}
                          readMoreText={props.resourcePage.readMoreText}
                          clockUrl={props.resourcePage.clock.url}
                          categoryFileURL={props.resourcePage.categoryFile.url}
                          featuredImage={post.featuredImage!}
                          id={post.id}
                          categoryName={post.category.categoryName}
                          minutesReading={post.minutesReading}
                          slug={post.slug}
                          author={post.author}
                          subtitle={post.subtitle}
                          title={post.title}
                          publishedDate={post.publishedDate}
                        />
                      </div>
                    );
                  }}
                  items={props.lastTwoFeaturedBlog.map((post) => ({
                    featuredImage: post.featuredImage,
                    id: post.id,
                    category: post.category,
                    minutesReading: post.minutesReading,
                    slug: post.slug,
                    subtitle: post.subtitle,
                    title: post.title,
                    publishedDate: post.publishedDate,
                  }))}
                  numShow={1}
                />
              </div>
            )}
          </div>
          <Divider />
          <div className={styles.container}>
            <Text
              size="sudoH1"
              type="light"
              title={content.faqTitle}
              textAlign="center"
              containerStyle={styles.titleWrapper}
            />
            <div className={styles.faqCards}>{faqs}</div>
          </div>
        </div>
      </div>
    </>
  );
}
