import Text from '../display/Text';
import NextImage from 'next/image';
import { Image, ResponsiveImageType } from 'react-datocms';
import styles from './featuredBlogCard.module.scss';
import Link from 'next/link';
import clsx from 'clsx';

import { timeConverter } from '../../hooks/timeConverter';
import Avatar from '../../public/images/author-avatar.svg';
import AvatarLight from '../../public/images/author-avatar-light.svg';

type featuredCardType = {
  title: string;
  subtitle: string;
  author: string;
  featuredImage?: { alt: string; responsiveImage: ResponsiveImageType };
  id: string;
  slug: string;
  categoryName: string;
  minutesReading: string;
  categoryFileURL: string;
  clockUrl: string;
  readingDurationText: string;
  isMobile: boolean;
  readMoreText: string;
  homePage?: boolean;
  publishedDate: string;
};

export const FeaturedCardContent = (featuredProps: featuredCardType) => {
  return (
    <>
      {featuredProps.featuredImage && (
        <Image data={featuredProps.featuredImage.responsiveImage} />
      )}
      <div className={styles.textWrapper}>
        <div className={styles.titleSubtitleWrapper}>
          <div className={styles.featuredHeader}>
            <Text
              title={timeConverter(featuredProps.publishedDate)}
              size="p2"
              textStyle={
                featuredProps.homePage
                  ? styles.homePageFooterText
                  : styles.footerText
              }
              textAlign="start"
            />
            <div className={styles.icons}>
              <div className={styles.iconTextMobile}>
                <NextImage
                  src={featuredProps.categoryFileURL}
                  height={18}
                  width={12}
                  alt="category icon"
                />
                <Text
                  title={featuredProps.categoryName}
                  size="p2"
                  textStyle={
                    featuredProps.homePage
                      ? styles.homePageFooterText
                      : styles.footerText
                  }
                />
              </div>
              <div className={styles.iconTextMobile}>
                <NextImage
                  src={featuredProps.clockUrl}
                  height={14}
                  width={16}
                  alt="Time to read icon"
                />
                <Text
                  title={`${featuredProps.minutesReading} ${featuredProps.readingDurationText}`}
                  size="p2"
                  textStyle={
                    featuredProps.homePage
                      ? styles.homePageFooterText
                      : styles.footerText
                  }
                />
              </div>
            </div>
          </div>
          <Link
            href={`/resources/${featuredProps.slug}`}
            shallow
          >
            <Text
              size="h3"
              type={`${featuredProps.homePage ? 'light' : 'dark'}`}
              textAlign="start"
              title={featuredProps.title}
              paddingTop={featuredProps.isMobile ? '16px' : '0px'}
              containerStyle={styles.titleContainer}
            />
          </Link>
          <Text
            size="p2"
            type="dark"
            textAlign="start"
            title={
              featuredProps.subtitle.length > 270
                ? featuredProps.subtitle.substring(0, 270) + '...'
                : featuredProps.subtitle
            }
            textStyle={clsx(
              !featuredProps.homePage
                ? styles.subtitle
                : styles.homePageSubtitle,
            )}
          />
        </div>
        <div className={styles.cardFooter}>
          <div className={styles.iconText}>
            <NextImage
              src={featuredProps.homePage ? AvatarLight : Avatar}
              height={19.5}
              width={19.5}
              alt="Author avatar icon"
            />
            <Text
              size="p2"
              type="light"
              textAlign="start"
              title={featuredProps.author}
              textStyle={
                featuredProps.homePage
                  ? styles.homePageFooterText
                  : styles.footerText
              }
            />
          </div>
          <Link
            href={`/resources/${featuredProps.slug}`}
            shallow
            aria-label={`redirects to /resources/${featuredProps.slug}`}
            className={
              featuredProps.homePage ? styles.homePageReadMore : styles.readmore
            }
          >
            {featuredProps.readMoreText}
          </Link>
        </div>
      </div>
    </>
  );
};
