 export const nth = function(d:number) {
  if(d < 10) {
    d = d % 10;
  }
  if (d > 3 && d < 21) {return `${d}th`;}
    switch (d % 10) {
      case 1:  return `${d}st`;
      case 2:  return `${d}nd`;
      case 3:  return `${d}rd`;
      default: return `${d}th`;
    }
};
  
 export const timeConverter = (date:string) => {
    const [year, month, day] = date.slice(0,10).split('-');
    const monthShortName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][+month-1];
    return `${monthShortName} ${nth(+day)}, ${year}`;
  };