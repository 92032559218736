'use client';

import React, {useState, useEffect} from 'react';
import { HomePageProps } from '~/app/(WithDefaultNavbar)/(homepage)/client';
import styles from './testimonialCard.module.scss';
import TestimonialItem from './testimonialItem';

interface Props{
      className: string;
      testimonials : HomePageProps['testimonials'];
}

const TestimonialCarousel = ({ className, testimonials }: Props) => {
      const [activeIndex, setActiveIndex] = useState(0);
      
      const updateIndex = (newIndex: number) =>{
            if (newIndex < 0){
                  newIndex = testimonials.length-1;
            }else if(newIndex >= testimonials.length){
                  newIndex = 0;
            }
            setActiveIndex(newIndex);
      };

      useEffect(() => {
            const interval = setInterval(() => {
              updateIndex(activeIndex + 1);
            }, 10000);
        
            return () => clearInterval(interval);
          // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [activeIndex, testimonials.length]);

      return (
       <div className={className}>
            <div className={styles.inner}>
                  <TestimonialItem isMobile={false} item={testimonials[activeIndex]}/>
            </div>
            <div className={styles.carouselBtns}>
                  <button className={styles.arrowBtn}
                          onClick={()=>{
                              updateIndex(activeIndex-1);}}
                  >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                           <path d="M15 18L9 12L15 6" stroke="#CFD1E5" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                  </button>
                  <div className={styles.indicators}>
                        {testimonials?.map((testimonial, index: number) => {
                              return (
                                    <button key={index} onClick={() => updateIndex(index)}>
                                          {index===activeIndex
                                                ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                  <circle cx="6" cy="6" r="6" transform="rotate(-90 6 6)" fill="#FFB40D"/>
                                                </svg>
                                                :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                  <circle cx="6" cy="6" r="6" transform="rotate(-90 6 6)" fill="#CFD1E5"/>
                                                </svg>
                                          }
                                    </button>
                              );
                        })}
                  </div>
                  <button className={styles.arrowBtn}
                          onClick={()=>{
                              updateIndex(activeIndex+1);
                          }}                          
                  >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M9 6L15 12L9 18" stroke="#CFD1E5" strokeWidth="2.6" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                  </button>
            </div>
       </div>
      );
};

export default TestimonialCarousel;