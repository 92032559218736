'use client';

import React, { useEffect, useRef, useState } from 'react';
import { getRefValue, getTouchEventData, useStateRef } from './hooks';
import styles from './swiper.module.scss';
import clsx from 'clsx';
import Image from 'next/image';
import LeftArrowButton from '../../public/images/leftArrowBtn.svg';
import RightArrowButton from '../../public/images/rightArrowBtn.svg';

const MIN_SWIPE_REQUIRED = 40;

function Swiper(props: {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  items: Array<any>;
  isTestimonial?: boolean;
  renderItem: (item: any) => JSX.Element;
  customStyle?: string;
  numShow: number;
  buttonsShow?: boolean;
}) {
  const containerRef = useRef<HTMLUListElement>(null);
  const minOffsetXRef = useRef(0);
  const curentOffSetXRef = useRef(0);
  const startXRef = useRef(0);
  const containerWidthRef = useRef(0);

  const [isSwiping, setIsSwiping] = useState<boolean>(false);
  const [isLeftDisabledBtn, setIsLeftDisabledBtn] = useState<boolean>(false);
  const [isRightDisabledBtn, setIsRightDisabledBtn] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const [offSetX, setOffSetX, offSetXRef] = useStateRef(0);

  const onTouchMove = (e: TouchEvent | MouseEvent) => {
    const currentX = getTouchEventData(e).clientX;
    const startX = getRefValue(startXRef);
    const diff = startX - currentX;
    const currentOffSetX = getRefValue(curentOffSetXRef);
    let newOffsetX = currentOffSetX - diff;

    if (newOffsetX > 0) {
      newOffsetX = 0;
    }
    if (newOffsetX < getRefValue(minOffsetXRef)) {
      newOffsetX = getRefValue(minOffsetXRef);
    }

    setOffSetX(newOffsetX);
  };
  const onTouchend = () => {
    const containerWidth = getRefValue(containerWidthRef);
    const currentOffSetX = getRefValue(curentOffSetXRef);

    let newOffsetX = getRefValue(offSetXRef);
    const diff = currentOffSetX - newOffsetX;

    if (Math.abs(diff) > MIN_SWIPE_REQUIRED) {
      if (diff > 0) {
        newOffsetX = Math.floor(newOffsetX / containerWidth) * containerWidth;
      } else {
        newOffsetX = Math.ceil(newOffsetX / containerWidth) * containerWidth;
      }
    } else {
      newOffsetX = Math.round(newOffsetX / containerWidth) * containerWidth;
    }

    setIsSwiping(false);
    setOffSetX(newOffsetX);
    setCurrentIndex(Math.abs(newOffsetX / containerWidth));

    window.removeEventListener('touchmove', onTouchMove);
    window.removeEventListener('touchend', onTouchMove);
  };

  const onTouchStart = (
    e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>,
  ) => {
    setIsSwiping(true);
    const currentOffSetX = getRefValue(offSetXRef);
    curentOffSetXRef.current = currentOffSetX;
    startXRef.current = getTouchEventData(e).clientX;

    const containerEl = getRefValue(containerRef);
    containerWidthRef.current = containerEl.offsetWidth / props.numShow;
    minOffsetXRef.current = containerEl.offsetWidth - containerEl.scrollWidth;

    window.addEventListener('touchmove', onTouchMove);
    window.addEventListener('touchend', onTouchend);
  };

  const indicatorOnClick = (idx: number) => {
    const containerEl = getRefValue(containerRef);
    const containerWidth = containerEl.offsetWidth / props.numShow;

    setCurrentIndex(idx);
    setOffSetX(-(containerWidth * idx));
  };

  const onRightClick = () => {
    const containerEl = getRefValue(containerRef);
    const containerWidth = containerEl.offsetWidth / props.numShow;
    minOffsetXRef.current = containerEl.offsetWidth - containerEl.scrollWidth;

    const condition =
      Math.ceil(offSetX - containerWidth) >= getRefValue(minOffsetXRef);
    setOffSetX(condition ? offSetX - containerWidth : minOffsetXRef.current);

    const index = currentIndex + 1;
    setCurrentIndex(index > 4 - props.numShow ? 0 : index);
  };
  const onLeftClick = () => {
    const containerEl = getRefValue(containerRef);
    const containerWidth = containerEl.offsetWidth / props.numShow;
    minOffsetXRef.current = containerEl.offsetWidth - containerEl.scrollWidth;

    const condition = offSetX + containerWidth <= 0;
    setOffSetX(condition ? offSetX + containerWidth : 0);

    const index = currentIndex - 1;
    setCurrentIndex(index < 0 ? 0 : index);
  };

  useEffect(() => {
    setIsLeftDisabledBtn(currentIndex === 0 ? true : false);
    setIsRightDisabledBtn(currentIndex === 4 - props.numShow ? true : false);
  }, [currentIndex, props.numShow]);

  return (
    <>
      {props.buttonsShow && (
        <button
          className={clsx({
            [styles.arrowButtonWrapper]: true,
            [styles.leftArrowButton]: true,
            [styles.arrowButtonDisabled]: isLeftDisabledBtn,
          })}
          disabled={isLeftDisabledBtn}
          onClick={onLeftClick}
        >
          <div className={styles.arrowButtonContent}>
            <Image src={LeftArrowButton} alt="left arrow" />
          </div>
        </button>
      )}
      <div
        className={clsx({ [styles.swiperContainer]: true }, props.customStyle)}
        onMouseDown={onTouchStart}
        onTouchStart={onTouchStart}
        role="presentation"
      >
        <ul
          ref={containerRef}
          id="swiperList"
          className={`${styles.swiperList} ${isSwiping ? styles.swipping : ''}`}
          style={{ transform: `translate3d(${offSetX}px, 0, 0)` }}
        >
          {props.items.map((item, index) => (
            <li
              key={index.toString()}
              className={styles.swiperItem}
              style={{ width: `calc(100% / ${props.numShow})` }}
            >
              {props.renderItem(item)}
            </li>
          ))}
        </ul>
        <ul className={styles.swiperIndicator}>
          {props.items.slice(0, 4 - props.numShow + 1).map((_item, index) => (
            <li className={styles.indicatorWrapper} key={index.toString()}>
              <button
                aria-label="next item"
                onClick={() => indicatorOnClick(index)}
                className={styles.wrapperDot}
              >
                <div
                  className={`${styles.swipperIndicatorItem} ${
                    index === Math.ceil(currentIndex)
                      ? styles.activeIndicator
                      : ''
                  }`}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
      {props.buttonsShow && (
        <button
          className={clsx({
            [styles.arrowButtonWrapper]: true,
            [styles.rightArrowButton]: true,
            [styles.arrowButtonDisabled]: isRightDisabledBtn,
          })}
          disabled={isRightDisabledBtn}
          onClick={onRightClick}
        > 
          <div className={styles.arrowButtonContent}>
            <Image src={RightArrowButton} alt="right arrow" />
          </div>
        </button>
      )}
    </>
  );
}
export default Swiper;
