import clsx from 'clsx';
import NextImage from 'next/image';
import styles from './page.module.scss';
import AccessAlliance from '~/public/clients/access_alliance.png';
import Centertown from '~/public/clients/centertown.png';
import Division from '~/public/clients/division.png';
import Expedium from '~/public/clients/expedium.png';
import NearNorth from '~/public/clients/near_north.png';
import NorthYork from '~/public/clients/north_york.png';
import Parkdale from '~/public/clients/parkdale.png';
import Wiebe from '~/public/clients/wiebe_medical_center.png';
import Rpchc from '~/public/clients/rpchc.png';
import Unison from '~/public/clients/unison.png';

export const OurClients = () => {
  return (
    <div className={styles.clientLogoContainer}>
      <div className={clsx(styles.clientLogoWrapper, styles.singleColumn)}>
        <NextImage src={Parkdale} alt="Parkdale" objectFit="cover" />
      </div>
      <div className={clsx(styles.clientLogoWrapper, styles.singleColumn)}>
        <NextImage src={Unison} alt="Unison" objectFit="cover" />
      </div>
      <div className={clsx(styles.clientLogoWrapper, styles.singleColumn)}>
        <NextImage src={Centertown} alt="Centertown" objectFit="cover" />
      </div>
      <div className={clsx(styles.clientLogoWrapper, styles.singleColumn)}>
        <NextImage src={Rpchc} alt="RPCHC" objectFit="cover" />
      </div>
      <div className={clsx(styles.clientLogoWrapper, styles.singleColumn)}>
        <NextImage src={NorthYork} alt="North York" objectFit="cover" />
      </div>
      <div className={clsx(styles.clientLogoWrapper, styles.twoColumn)}>
        <NextImage src={Wiebe} alt="wiebe_medical_center" objectFit="cover" />
      </div>
      <div className={clsx(styles.clientLogoWrapper, styles.singleColumn)}>
        <NextImage src={NearNorth} alt="Near North" objectFit="cover" />
      </div>
      <div className={clsx(styles.clientLogoWrapper, styles.twoColumn)}>
        <NextImage src={Division} alt="Division" objectFit="cover" />
      </div>
      <div className={styles.spaceColumn} />
      <div className={clsx(styles.clientLogoWrapper, styles.singleColumn)}>
        <NextImage src={Expedium} alt="Expedium" objectFit="cover" />
      </div>
      <div className={clsx(styles.clientLogoWrapper, styles.singleColumn)}>
        <NextImage
          src={AccessAlliance}
          alt="Access Alliance"
          objectFit="cover"
        />
      </div>
    </div>
  );
};
