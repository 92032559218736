import clsx from 'clsx';

import styles from './Divider.module.scss';

const Divider = (props: { dividerStyle?: string }) => {
  return <div className={styles.container}>
    <div className={clsx(styles.divider, props.dividerStyle)}></div>
  </div>;
};

export default Divider;