import React from 'react';
import styles from './testimonialCard.module.scss';
import Image from 'next/image';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: any;
  isMobile: boolean;
}

export default function TestimonialItem({ item, isMobile }: Props) {
  return !isMobile ? (
    <div className={styles.testimonialCard}>
      <Image
        src={item.avatar.url}
        alt="Avatar"
        width={104}
        height={104}
        className={styles.avatar}
      />
      <div className={styles.content}>
        <div className={styles.discription}>{item.testimonialDescription}</div>
        <div className={styles.footer}>
          <div className={styles.author}>{item.userId}</div>
          <div className={styles.role}>{item.userRole}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.mobileTestimonialCard}>
      <Image
        src={item.avatar.url}
        alt="Avatar"
        width={104}
        height={104}
        className={styles.avatar}
      />
      <div className={styles.content}>
        <div className={styles.discription}>{item.testimonialDescription}</div>
      </div>
      <div className={styles.footer}>
        <div className={styles.author}>{item.userId}</div>
        <div className={styles.role}>{item.userRole}</div>
      </div>
    </div>
  );
}
