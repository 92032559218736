import clsx from 'clsx';
import { Image, ResponsiveImageType } from 'react-datocms';
import Text from '../../display/Text';
import styles from './ImageParagraph.module.scss';
import Link from 'next/link';
import BaseButton from '../../Base/TaliButton';
import { ComponentProps } from 'react';

export type muxVideoType = {
  muxPlaybackId: string;
};

type ImageParagraphProps = {
  title: string;
  body: string;
  image: {
    video?: muxVideoType | null;
    responsiveImage?: ResponsiveImageType | null;
  };
  button?: {
    label: string;
    href: string;
  };
  buttonLink?: Partial<ComponentProps<typeof BaseButton>> & {
    text: string;
    link: string;
  };
  containerStyle?: string;
  textAlign?: 'start' | 'end' | 'center';
  containerTextStyle?: string;
  reverseOrder?: boolean;
  withBackground?: boolean;
};

export default function ImageParagraph(props: ImageParagraphProps) {
  const {
    title,
    body,
    image,
    button,
    buttonLink,
    containerStyle,
    textAlign,
    containerTextStyle,
    reverseOrder,
    withBackground,
  } = props;

  const videoImageInfo: ResponsiveImageType = {
    src: `https://image.mux.com/${image.video?.muxPlaybackId}/animated.webp?width=640`,
    aspectRatio: 2.4,
    width: 437,
    height: 310,
  };
  const imageInfo = image.responsiveImage ?? videoImageInfo;

  return (
    <div
      className={clsx([styles.container, containerStyle], {
        [styles.containerWithBackground]: withBackground,
      })}
    >
      <div
        className={clsx('flex-1', [
          styles.textContainer,
          containerTextStyle,
          reverseOrder ? styles.reverseOrder : null,
        ])}
      >
        <Text
          size="h2"
          type="light"
          title={title}
          containerStyle={styles.paragraph}
          paddingBottom="18px"
          textAlign={textAlign ? textAlign : 'start'}
        />
        {body ? (
          <Text
            size="p1"
            type="light"
            title={body}
            containerStyle={styles.paragraph}
            textAlign={textAlign ? textAlign : 'start'}
          />
        ) : null}
        {button ? (
          <div className={clsx([styles.imageParagraphBtn])}>
            <Link href={button.href}>{button.label}</Link>
          </div>
        ) : null}
        {buttonLink ? (
          <BaseButton
            id="image-paragraph-link"
            {...buttonLink}
            className={clsx(buttonLink.className, 'mt-6')}
          >
            {buttonLink.text}
          </BaseButton>
        ) : null}
      </div>
      {/* <div className="w-5/6 desktop:w-[437px]  relative"> */}
      <Image
        className={styles.imageContainer}
        data={imageInfo}
        // layout="fill"
        objectFit="cover"
        // objectPosition="50% 50%"
      />
      {/* </div> */}
    </div>
  );
}
