'use client';
import { useRouter } from 'next/navigation';
import { addEvent, Events } from '../../services/gtag';
import { useEffect } from 'react';

interface Props {
  text: string;
  className?: string;
  event?: Events;
  onClick?: () => void;
}

export default function LoginButton(props: Props) {
  const router = useRouter();
  useEffect(() => {
    router.prefetch('/install');
  }, [router]);
  {
    /* eslint-disable-next-line @next/next/no-html-link-for-pages */
  }
  return (
    // eslint-disable-next-line @next/next/no-html-link-for-pages
    <a
      className={props.className}
      href="/api/auth/login"
      onClick={() => {
        props.onClick?.();
        addEvent(props.event ? props.event : 'login');
      }}
    >
      <div>{props.text}</div>
    </a>
  );
}
